import React from "react";
import { Component } from 'react';

class Player extends Component {

    playerSelected = () => {
        this.props.playerSelected(this.props.name);
    }

    render () {
        return(
            <div className={(this.props.selectedPlayer === this.props.name) ? 'column selected-player' : 'column'} onClick={this.playerSelected}>
                {this.props.name}
            </div>
        )
    }
}
export default Player;