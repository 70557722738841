import './css/matches.css';
import './App.css';
import { Component } from 'react';
import Player from './Components/Player';
import Match from './Components/Match';

class App extends Component {

  state = {
    player: '',
  }

  targetURL = 'https://92f90kftpf.execute-api.us-east-2.amazonaws.com/prod/match';

  players = ["Anne", "Colleen", "Katie", "Leslie", "Marilee", "Susan", "Tricia"];

  matches = [];

  componentDidMount() {

    fetch(`${this.targetURL}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(response => response.json())
      .then(response => {
        this.matches = response.Items;
        this.setState({ player: '' });
      })
      .catch(err => {
        console.log(err);
      })
  }

  playerSelected = (playerName) => {
    this.matches = this.matches.map(match => {
      let mapMatch = match;
      delete mapMatch.playerAvail;

      if (match.available.find(player => player === playerName)) {
        mapMatch["playerAvail"] = true;
      }
      return mapMatch;
    })
    this.setState({ player: playerName });
  }

  saveUpdate = (match, playerAvail) => {

    delete match.playerAvail;
    fetch(`${this.targetURL}/${match.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(match),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .catch(err => {
        console.log(err);
      })

    if (!playerAvail) match["playerAvail"] = true;

    let matchIndex = this.matches.indexOf(match.id);
    if (matchIndex >= 0) this.matches[matchIndex] = match;
  }

  render() {
    return (
      <div className="App">
        <div className='row'>
          {this.players.map(player =>
            <Player key={player} name={player} selectedPlayer={this.state.player} playerSelected={this.playerSelected} />)}
        </div>
        {/* <div>We need to figure out when we can do our make-ups.  Please see the dates beginning with *'s and mark them if they potentially work for you.</div> */}
        <div className='title'>Match Schedule</div>
        <div>
          {this.matches.sort((match1, match2) => {
            if (match1.date < match2.date) 
            return -1
            else return 1
          })
          .map(match =>
            <Match key={match.id} match={match} selectedPlayer={this.state.player} saveUpdate={this.saveUpdate} />)}
        </div>

      </div>

    );
  }
}

export default App;
