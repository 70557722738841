import React from "react";
import { Component } from 'react';

class Match extends Component {

    state = {
        updatedInfo: false
    }

    saveChange = () => {
        let savedPlayerAvail = this.props.match.playerAvail

        if (this.props.selectedPlayer) {
            if (this.props.match.playerAvail) {
                let index = this.props.match.available.indexOf(this.props.selectedPlayer);
                this.props.match.available.splice(index, 1);
            } else {
                this.props.match.available.push(this.props.selectedPlayer);
            }

            this.props.saveUpdate(this.props.match, savedPlayerAvail);
            this.setState({ updatedInfo: true });
        }
    }

    render() {
        return (
            <div className={this.props.match.playerAvail ? "available match" : "match"} onClick={this.saveChange}>
                <div className="match-first">{`${this.props.match.date.substring(4)} - ${this.props.match.course}`}</div>
                <div className="match-line">
                   <span className="title">Available: </span>{this.props.match.available.sort().map(player => `${player} `)}
                </div>
 
                <div className={(this.props.match.scheduled.length > 0) ? "match-line" : "hide"}>
                  <span className={(this.props.match.scheduled.indexOf(this.props.selectedPlayer)>=0) ? "scheduled" : ""}> 
                  <span className="title">Scheduled: </span>{this.props.match.scheduled.sort().map(player => `${player} `)}</span> 
                </div>
            </div>
        )
    }
}
export default Match;